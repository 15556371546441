import './SlideShow.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faClipboardCheck, faCog, faPhone } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect } from 'react';

function SlideShow() {
   
   const [activeIndex, setActiveIndex] = useState(0);

useEffect(() => {
  const interval = setInterval(() => {
    setActiveIndex((prevIndex) => (prevIndex === 2 ? 0 : prevIndex + 1));
  }, 4000);

  return () => clearInterval(interval);
}, []);

const images = ['./SlideShowImage1.jpg','./SlideShowImage1.jpg' , './SlideShowImage1.jpg'];

return (

    <>

<div id="carouselExample" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-inner">
    {images.map((image, index) => (
      <div key={index} className={`carousel-item${index === activeIndex ? ' active' : ''}`} style={{ position: 'relative' }}>
        <img
          src={image}
          className="d-block w-100 slide-img"
          style={{ width: '100%', transition: 'transform 5s', transform: index === activeIndex ? 'scale(1.1)' : 'scale(1)' }}
          alt={`Slide ${index}`}
        />
        <div className="carousel-overlay"></div>
        {/* <div className="carousel-caption" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1 }}>
          <h1 className="shiny-text" style={{ fontSize: '3.3rem', color: 'white', fontWeight: 'bold' }}>OJAS PETROCHEM</h1>
          <h3 style={{ fontSize: '2.2rem', color: 'white' }}>SINCE 2015</h3>
        </div> */}
      </div>
    ))}
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev" onClick={() => setActiveIndex((prevIndex) => (prevIndex === 0 ? 2 : prevIndex - 1))}>
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next" onClick={() => setActiveIndex((prevIndex) => (prevIndex === 2 ? 0 : prevIndex + 1))}>
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>

<div class="about-section container">
<div className="mt-5 future">
        <div class="header-container">
  <h1 class="future-head">About Ojas Lubricants</h1>
   <div class="text-white m-1 p-3">
                <button type="button" class="btn getBtn border border-black rounded-0">View More</button>
                   </div>
</div>
</div>
<p class="about-info">Ojas Petrochem Company is a leading supplier of lubricant oils and greases for industrial use. Our company has established a strong position in the market, by providing high quality products at competitive prices. We have established long-term relationships with our customers, who are satisfied with our products. The company has been constantly expanding its capacity and now serves as a leading manufacturer of lubricants in the region.</p>
</div>

<div className="container">
  <div className="row">
    <div className="col-6 col-md-3 yellow-hover first-four-cards">
      <div className="card">
        <div className="card-body text-center">
          <FontAwesomeIcon icon={faUsers} size="3x" />
          <h5 className="card-title">Customer Support</h5>
          <p className="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </div>
      </div>
    </div>
    <div className="col-6 col-md-3 yellow-hover first-four-cards" >
      <div className="card">
        <div className="card-body text-center">
          <FontAwesomeIcon icon={faClipboardCheck} size="3x" />
          <h5 className="card-title">Quality Assurance</h5>
          <p className="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </div>
      </div>
    </div>
    <div className="col-6 col-md-3 yellow-hover first-four-cards" >
      <div className="card">
        <div className="card-body text-center">
          <FontAwesomeIcon icon={faCog} size="3x" />
          <h5 className="card-title">Technical Support</h5>
          <p className="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </div>
      </div>
    </div>
    <div className="col-6 col-md-3 yellow-hover first-four-cards" >
      <div className="card">
        <div className="card-body text-center">
          <FontAwesomeIcon icon={faPhone} size="3x" />
          <h5 className="card-title">24/7 Customer Helpline</h5>
          <p className="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit .</p>
        </div>
      </div>
    </div>
  </div>
</div>         

</>
  );
}

export default SlideShow;